import React, { useEffect, useRef, useState } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import theme from "../../theme/data.json";

export default function Project({ image, x, title }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const mainControls = useAnimation();

  useEffect(() => {
    setIsSmallScreen(window.innerWidth <= 640);
  }, [window.innerWidth]);

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  return (
    <motion.figure
      style={{ color: theme.text }}
      className="w-full relative min-h-48 sm:mt-12 sm:mb-12"
      ref={ref}
      variants={{
        hidden: { opacity: 0, y: 75 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 0.5, delay: 0.25 }}
    >
      <div
        className="w-full sm:w-1/2 lg:w-1/3 top-0 absolute flex flex-col justify-start items-center"
        style={!isSmallScreen ? { left: `${x}%` } : {}}
      >
        <div
          className="w-full aspect-video"
          style={{
            background: "transparent",
            backgroundImage: `url(${image})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center center",
          }}
        ></div>
        <marquee>
          <h2 className="text-sm font-bold" style={{ color: theme.accent }}>
            {title}
          </h2>
        </marquee>
      </div>
    </motion.figure>
  );
}
