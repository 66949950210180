import React from "react";
import theme from "../../theme/data.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/pro-regular-svg-icons";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";

export default function Contact() {
  return (
    <section
      className="pt-20 p-6 pb-20"
      id="contact"
      style={{ color: theme.text }}
    >
      <h2
        style={{ color: theme.text }}
        className="text-3xl font-bold text-center w-full"
      >
        Links
      </h2>
      <figure className="flex flex-col pt-10 gap-10">
        <a
          className="flex flex-col justify-center items-center text-xl gap-2"
          href="mailto: melvinlockx@ziggo.nl"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          <FontAwesomeIcon icon={faEnvelope} /> melvinlockx@ziggo.nl
        </a>
        <a
          className="flex flex-col justify-center items-center text-xl gap-2"
          href="tel: +31625209282"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          <FontAwesomeIcon icon={faPhone} /> +31 6 25209282
        </a>
        <a
          className="flex flex-col justify-center items-center text-xl gap-2"
          href="https://www.linkedin.com/in/melvin-lockx-08868620b/"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          <FontAwesomeIcon icon={faLinkedin} /> Melvin Lockx
        </a>
        <a
          className="flex flex-col justify-center items-center text-xl gap-2"
          href="https://github.com/Arzoka"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          <FontAwesomeIcon icon={faGithub} /> Arzoka
        </a>
      </figure>
    </section>
  );
}
