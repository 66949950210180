import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import theme from "../../theme/data.json";

export default function Experience({
  image,
  title,
  position,
  date,
  workedWith,
  working,
  link,
}) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  return (
    <motion.figure
      style={{ color: theme.text }}
      className="w-full min-h-32 pt-1 pb-1 border-b-2 border-gray-800 flex flex-col justify-start sm:h-full sm:border-r-2 sm:p-4"
      ref={ref}
      variants={{
        hidden: { opacity: 0, y: 75 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 0.5, delay: 0.25 }}
    >
      <figure className="w-full min-h-32 flex flex-row justify-start gap-4">
        {!link ? (
          <figure className="w-1/3 h-full aspect-square flex justify-start items-start pt-4">
            <img
              className="w-full rounded-lg"
              src={image}
              alt="experience img"
            ></img>
          </figure>
        ) : (
          <a
            className="w-1/3 h-full aspect-square flex justify-start items-start pt-4"
            target="_blank"
            href={link}
            rel="noreferrer"
          >
            <img
              className="w-full rounded-lg"
              src={image}
              alt="experience img"
            ></img>
          </a>
        )}

        <figure className="w-2/3 h-5/6 flex flex-col justify-start pt-2 pb-2">
          <h2 className="font-bold text-md">{position}</h2>
          <h3 className="text-md">{title}</h3>
          <h3 className="opacity-75 text-sm">{date}</h3>
        </figure>
      </figure>

      <figure>
        <h3 className="mt-2 mb-2 font-bold">
          {working ? "Working" : "Worked"} with:
        </h3>
        <div className="w-full flex flex-wrap gap-1 mb-2">
          {workedWith.map((work) => (
            <div
              className="rounded-lg pl-4 pr-4 pt-1 pb-1 text-xs"
              style={{
                background: "#ffffff10",
                borderWidth: 1,
                borderColor: "#ffffff30",
              }}
            >
              {work.toUpperCase()}
            </div>
          ))}
        </div>
      </figure>
    </motion.figure>
  );
}
