import React from "react";
import theme from "../../theme/data.json";
export default function Footer() {
  const date = new Date();

  return (
    <footer
      className="w-full h-24 flex flex-row justify-end items-center pl-6 pr-6"
      style={{ background: "#ffffff10", color: theme.text }}
    >
      <p>© All rights reserved {date.getFullYear()}</p>
    </footer>
  );
}
