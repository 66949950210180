import React from "react";
import theme from "../../theme/data.json";

export default function About() {
  return (
    <section
      className="min-h-screen pt-20 p-6"
      id="about"
      style={{ color: theme.text }}
    >
      <h2
        style={{ color: theme.text }}
        className="text-3xl font-bold text-center w-full lg:text-4xl sm:mb-10"
      >
        About me
      </h2>
      <figure className="flex flex-col gap-4 pt-8 tracking-wide sm:gap-8 items-center">
        <article className="w-full sm:w-2/3 lg:w-1/2">
          <h3 className="font-bold text-lg lg:text-2xl">Goals</h3>
          <p className="sm:text-lg">
            As mentioned before, I'm striving to become a full-stack developer,
            since around 7 years ago starting on ROBLOX as a game developer I've
            had an extreme interest in anything programming. And I hope to be
            able to build anything I want from scratch in the future.
          </p>
        </article>
        <article className="w-full sm:w-2/3 lg:w-1/2">
          <h3 className="font-bold text-lg lg:text-2xl">Current interests</h3>
          <p className="sm:text-lg">
            Currently I'm mostly interested in improving my back-end skills and
            working on my code structures, for this I'm trying out Laravel and
            figuring out more about the MVC structure.
          </p>
        </article>

        <article className="w-full sm:w-2/3 lg:w-1/2">
          <h3 className="font-bold text-lg lg:text-2xl">Personal life</h3>
          <p className="sm:text-lg">
            Outside of all that I have a personal life too, I enjoy going on
            dates with my loving girlfriend, and playing games.
          </p>
        </article>
        <div className="w-1/2 flex flex-col items-left">
          <img
            className="w-full sm:w-1/3"
            src="meandmygirlfriend.webp"
            alt="me and my girlfriend"
          />
          <p className="text-sm">- My girlfriend and I</p>
        </div>
      </figure>
    </section>
  );
}
