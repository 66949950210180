import React, { useState } from "react";
import theme from "../../theme/data.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ConfettiExplosion from "react-confetti-explosion";

export default function Heading() {
  const [imageClick, setImageClick] = useState(0);
  return (
    <section
      className="flex flex-col justify-start items-center min-h-screen gap-2 pt-20 relative pb-20"
      style={{ color: theme.text }}
    >
      {imageClick === 3 && (
        <ConfettiExplosion colors={[theme.primary, theme.secondary]} />
      )}
      <figure className="w-2/3 sm:w-1/3 lg:w-1/6 relative">
        <div
          className="pfp-back"
          style={{
            background: `linear-gradient(${theme.primary},${theme.secondary})`,
            zIndex: 1,
          }}
        ></div>
        <img
          onClick={() => {
            if (imageClick < 3) {
              setImageClick(imageClick + 1);
            }
          }}
          className="rounded-full z-10 pfp left-0 top-0"
          style={{
            position: imageClick === 3 ? "relative" : "absolute",
            filter: imageClick === 3 ? "brightness(1)" : "brightness(0)",
          }}
          src="Taiga.png"
          alt="Taiga"
        />
        <img
          onClick={() => {
            setImageClick(imageClick + 1);
          }}
          className="rounded-full z-10 relative pfp"
          style={{
            transform:
              imageClick === 1
                ? "rotate(25deg)"
                : imageClick === 2
                ? "rotate(65deg)"
                : imageClick === 3
                ? "rotate(80deg)"
                : "rotate(0deg)",
            display: imageClick === 3 ? "none" : "block",
          }}
          src="me.webp"
          alt="me"
        />
      </figure>
      <h1 className="font-bold text-3xl w-3/4 text-center">
        {imageClick === 3 ? "Hi, I'm Taiga!" : "Hi, my name is Melvin Lockx"}
      </h1>
      <h2 className="text-lg w-3/4 text-center p-6">
        {imageClick === 3
          ? "And I'm Melvin's precious "
          : "I'm a programming enthousiast striving to become a "}
        <span className="font-bold text-xl" style={{ color: theme.accent }}>
          {imageClick === 3 ? "puppy" : "full-stack developer"}
        </span>
      </h2>
      <a href="#about" style={{ display: "inline-block" }}>
        <FontAwesomeIcon
          icon={faChevronDown}
          className="text-4xl absolute bottom-16 left-1/2 -translate-x-1/2"
        ></FontAwesomeIcon>
      </a>
    </section>
  );
}
