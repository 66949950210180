import "../styles/Tailwind.css";
import theme from "../theme/data.json";
import Heading from "../components/sections/Heading";
import Projects from "../components/sections/Projects";
import Experiences from "../components/sections/Experiences";
import About from "../components/sections/About";
import Contact from "../components/sections/Contact";
import Footer from "../components/sections/Footer";

function App() {
  return (
    <div
      style={{ background: theme.background }}
      className="w-full min-h-screen"
    >
      <Heading />
      <About />
      <Projects />
      <Experiences />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
