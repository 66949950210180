import React from "react";
import Experience from "../elements/experience";
import theme from "../../theme/data.json";

export default function Experiences({ image, title, date }) {
  return (
    <section className="pt-0" id="experiences">
      <h2
        style={{ color: theme.text }}
        className="text-3xl font-bold text-center w-full pb-10 pt-20"
      >
        Experiences
      </h2>
      <figure className="min-h-full w-full flex flex-col justify-between items-center border-t-2 border-gray-800 sm:grid sm:grid-cols-2 lg:grid-cols-3">
        <Experience
          image={"Placeholder.webp"}
          title={"Mejatech"}
          position={"Founder - Software engineer"}
          link={null}
          date="Sep 2023 - Present"
          workedWith={[
            "Javascript",
            "Typescript",
            "C++",
            "PHP",
            "Laravel",
            "React.js",
            "Next.js",
            "React-native",
            "Docker",
          ]}
          working={true}
        />
        <Experience
          image={"tipzy.webp"}
          title={"Tipzy - Internship"}
          position={"Web/App developer"}
          link={"https://www.tipzy.io/"}
          date="Jul 2023 - Oct 2023"
          workedWith={[
            "TailwindCSS",
            "Typescript",
            "Sass",
            "React.js",
            "React-Native",
            "Next.js",
          ]}
          working={false}
        />
        <Experience
          link={"https://www.flensbv.nl/"}
          image={"flens.webp"}
          title={"Flens B.V."}
          position={"Database administrator"}
          date="Feb 2023 - Present"
          workedWith={["Proteus", "SQL", "Databases"]}
          working={true}
        />
        <Experience
          link={"https://ma-web.nl/"}
          image={"MediaCollege.webp"}
          title={"Mediacollege - Student"}
          position={"Media Developer"}
          date="Sep 2022 - Jul 2026"
          workedWith={[
            "MySQL",
            "Docker",
            "HTML",
            "CSS",
            "Sass",
            "Scss",
            "PHP",
            "Laravel",
            "React.js",
            "Adobe XD",
            "Git",
            "Xampp",
            "MySQL",
            "C++",
            "Javascript",
            "Python",
            "Raspberry Pi",
            "Arduino",
          ]}
          working={true}
        />
      </figure>
    </section>
  );
}
