import React from "react";
import theme from "../../theme/data.json";
import Project from "../elements/project";

export default function Projects() {
  return (
    <section
      className="min-h-screen pt-20 mb-20 p-6 flex flex-col gap-32"
      id="projects"
    >
      <h2
        style={{ color: theme.text }}
        className="text-3xl font-bold text-center w-full mb-12"
      >
        Projects
      </h2>
      <Project
        image={"projects/PBServices.png"}
        x={0}
        title={"PB Services, custom CMS using React-native, Review system"}
      />
      <Project
        image={"projects/TipzyDashboard.png"}
        x={45}
        title={"Dashboard for Tipzy, using Next.js"}
      />
      <Project
        image={"projects/FoodApp.png"}
        x={10}
        title={"Food app using React native"}
      />
    </section>
  );
}
